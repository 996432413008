import React from 'react'
import { permalink } from 'constants/routes'
import { NextPage } from 'next'
import Dots from 'theme/ui/icons/bend-icons/loaders/dots'

import { useRedirect } from 'components/common/redirect'

const IndexPage: NextPage = () => {
  useRedirect(permalink.overview)
  return <Dots />
}
export default IndexPage
